import React,{ FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store";
import "./sessionTimes.scss";
import { setReinitializeScroll } from "../../../store/application/action";
import { setTicketLessFb } from "../../../store/foodAndBeverage/action";
import {
  getTicketBookingDetails,
  toggleTicketModal,
} from "../../../store/ticketBooking/action";
import { ISessionDetails } from "../../../models/cinema";
import { COUNTRY } from "../../../constants/app";
import { Tooltip } from "react-tooltip";

interface ISessionTimesProps {
  sold?: boolean;
  isBookable: number;
  data: [];
}

const SessionTimes: FC<ISessionTimesProps> = ({ data, isBookable }) => {
  const { userDetails,isLoggedIn, settings, currentCinema, countryId } = useSelector(
    (state: RootState) => ({
      userDetails: state.authReducer.userDetails,
      isLoggedIn: state.authReducer.isLoggedIn,
      settings: state.applicationReducer.settings,
      currentCinema: state.applicationReducer.currentCinema,
      countryId: state.applicationReducer.countryId,
    })
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [addClass, setAddClass] = useState<string>("");

  useEffect(() => {
    if (data.length > 2 && data.length <= 4) {
      setAddClass("three");
    }else if (data.length > 4 && data.length <= 6) {
      setAddClass("five");
    } else if (data.length >= 7 && data.length <= 8) {
      setAddClass("seven");
    } else if (data.length >= 9 && data.length < 10) {
      setAddClass("nine");
    } else if (data.length >= 11) {
      setAddClass("session_scroll");
    }
    dispatch(setReinitializeScroll(true));
  }, [data]);

  const openTicketModal = (data: ISessionDetails) => {
    // dispatch(setTicketLessFb(false));
    // dispatch(
    //   getTicketBookingDetails({
    //     cinemaId: currentCinema.slug,
    //     sessionId: data.id,
    //     reservedSeating: data.reservedSeating,
    //     screenType: data.session_attributes,
    //     countryId: countryId,
    //     covidFlag: currentCinema.covidFlag,
    //   })
    // );
    // dispatch(toggleTicketModal(true));
    navigate(
      `/cinemas/${currentCinema.slug}/sessions/${data.id}/${data.movieId}`
    );
  };

  return (
    <div className={`mCustomScrollbar ${addClass}`}>
      <div className="timecard-wrap">
        {data.map((timesObj: any, index: number) => (
          <div
            key={`sessionTimes_${index}`}
            onClick={() => isBookable === 1 && openTicketModal(timesObj)}
            className={`times-card ${
              timesObj.Performance_status_code == 0 ? "active" : ""
            }
            ${
              timesObj.Performance_status_code == 1 ||
              settings.enableTicketBooking == 0 ||
              isBookable != 1
                ? "inactive"
                : ""
            }`}
          >
            <div className="legend_wrap">
              {timesObj.Performance_status_code == 1 ? (
                <div className="legends_icon soldout_icon">&nbsp;</div>
              ) : null}

              {timesObj.Performance_status_code == 0 &&
              (timesObj.session_attributes == "Standard" ||
                !timesObj.session_attributes ||
                (timesObj.session_attributes.toLowerCase() != "premium" &&
                  timesObj.session_attributes.toLowerCase() != "titanxc" &&
                  timesObj.session_attributes.toLowerCase() != "titan luxe" &&
                  timesObj.session_attributes.toLowerCase() != "titanluxe")) ? (
                <div className="legends_icon standard_icon">&nbsp;</div>
              ) : null}
              {timesObj.Performance_status_code == 0 ? (
                <div
                  className={`legends_icon 
                            ${
                              timesObj.session_attributes.toLowerCase() ==
                              "premium"
                                ? "premium_icon"
                                : timesObj.session_attributes.toLowerCase() ==
                                  "gold"
                                ? "gold_icon"
                                : timesObj.session_attributes.toLowerCase() ==
                                  "soho"
                                ? "soho_icon"
                                : timesObj.session_attributes.toLowerCase() ==
                                  "titanxc"
                                ? "titanxc_icon"
                                : timesObj.session_attributes.toLowerCase() ==
                                    "titan luxe" ||
                                  timesObj.session_attributes.toLowerCase() ==
                                    "titanluxe"
                                ? "titan_luxe_icon"
                                : timesObj.session_attributes.toLowerCase() ==
                                "rooftop"
                              ? "rooftop_icon"
                                : " "
                            }`}
                >
                  &nbsp;
                </div>
              ) : null}
            </div>
            <div className="time">{timesObj.showtime}</div>
            {/* {props?.sold ? ( */}
            {timesObj.Performance_status_code == 1 ? 
              <span className="sold_out_txt">SOLD OUT</span>
              :

            <div className="seat_wrap">
              <div
                className={`fill_status ${
                  timesObj?.seatsFillStatus == 0
                    ? "no_fill"
                    : timesObj?.seatsFillStatus == 1
                    ? "slow_fill"
                    : timesObj?.seatsFillStatus == 2
                    ? "fast_fill"
                    : "no_fill"
                }`}
              ></div>
              {timesObj.Performance_status_code == 0 ? (
                <div className="flag_wrap">
                  {timesObj.flags.map((flagObj: any, i: number) => (
                    <>
                    <span className="flag_text"  data-tooltip-id={`flag-tooltip_${i}`} data-tooltip-html={flagObj.Description}>{flagObj.short}</span>
                    <Tooltip id={`flag-tooltip_${i}`} className="tool-tip" />
                    </>
                  ))}
                </div>
              ) : null}
            </div>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SessionTimes;
