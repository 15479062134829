import React,{ FC } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import "./foodAndBeverageHeader.scss";

interface IFoodAndBevarageHeaderProps {}

const FoodAndBevarageHeader: FC<IFoodAndBevarageHeaderProps> = () => {
  const { modalHeaderText, ticketDetails, ticketLessFb, isFutureFb } =
    useSelector((state: RootState) => ({
      modalHeaderText: state.foodBeverageReducer.modalHeaderText,
      ticketLessFb: state.foodBeverageReducer.ticketLessFb,
      isFutureFb: state.foodBeverageReducer.isFutureFb,
      ticketDetails: state.ticketBookingReducer.ticketDetails,
    }));

  return (
    <div className="food_header_wrap">
      <div className="col-10 col-md-8 pd-0">
        <div>
          <div className="title">{modalHeaderText}</div>
        </div>
        {!ticketLessFb ? (
          <div>
            <span className="time">
              TOTAL TICKET PRICE: ${(ticketDetails?.total?.toFixed(2))}
            </span>
          </div>
        ) : null}
        {isFutureFb ? (
          <div>
            {/* <div className="media_wrap_header ">
        <div className="date-title">{{headdata?.date}}</div>
        <div className="media_content">
          <div className="thumb_img">
            <img src="{{headdata?.movieImage}}" alt="">
          </div>
          <div className="list_details">
            <p className="title">{{headdata?.movieName}}</p>
            <p className="item_list">{{headdata?.time}} - {{headdata?.theatreId | titlecase}}</p>
            <p className="item_list"></p>
          </div>
        </div>
      </div> */}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default FoodAndBevarageHeader;
