import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useMatch, useNavigate } from "react-router-dom";
import { IMAGE_URL_CONFIG, URLS } from "../../../constants/url";
import { COUNTRY, LOCATION_ADD_ON } from "../../../constants/app";
import { RootState } from "../../../store";
import {
  getCinemaWatcher,
  openSliderAction,
  setCinemaAction,
} from "../../../store/application/action";
import "./header.scss";
import LocationDropDown from "../../../components/UI/DropDown/Location";
import {
  findCinemaObject,
  formatCinemaNearby,
  formatLocation,
} from "../../../utils/formatter";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../constants/path";
import Storage from "../../../utils/storage";
import { showLogin } from "../../../store/auth/action";
import { ICinemas } from "../../../models/cinema";
import { searchMovie } from "../../../store/films/action";
import { toast } from "react-toastify";
let _ = require("lodash");

interface IHeaderProps {}

const Header: FC<IHeaderProps> = () => {
  const { countryId, cinemas, currentCinema, isLoggedIn, cart, userDetails } = useSelector(
    (state: RootState) => ({
      countryId: state.applicationReducer.countryId,
      cinemas: state.applicationReducer.cinemas,
      currentCinema: state.applicationReducer.currentCinema,
      isLoggedIn: state.authReducer.isLoggedIn,
      cart: state.giftCardReducer.cart,
      userDetails: state.authReducer.userDetails
    })
  );

  const LINK = {
    MOVIES: {
      LABEL: "MOVIES",
      TO: `/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`,
      PATHS: [
        useMatch(`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`),
        useMatch(`/${ROUTES.MOVIES}/${ROUTES.COMING_SOON}`),
        useMatch(`/${ROUTES.MOVIES}/${ROUTES.KIDS_NOW_SHOWING}`),
        useMatch(`/${ROUTES.MOVIES}/${ROUTES.KIDS_COMING_SOON}`),
        useMatch(`/${ROUTES.MOVIES}/${ROUTES.KIDS_ACTIVITIES}`),
        useMatch(`/${ROUTES.MOVIE_DETAIL_SEARCH}`),
      ],
    },
    CINEMAS: {
      LABEL: "CINEMAS",
      TO: `/${ROUTES.CINEMAS}`,
      PATHS: [useMatch(`/${ROUTES.CINEMAS}`)],
    },
    ACCOUNTS: { LABEL: "ACCOUNT", TO: ``, paths: [] },
    RWARDS: {
      LABEL: "REWARDS",
      TO: `/${ROUTES.REWARDS}/${ROUTES.SUMMARY}`,
      PATHS: [
        useMatch(`/${ROUTES.REWARDS}/${ROUTES.SUMMARY}`),
        useMatch(`/${ROUTES.REWARDS}/${ROUTES.REWARDITEM}`),
        useMatch(`/${ROUTES.REWARDS}/${ROUTES.BENEFITS}`),
        useMatch(`/${ROUTES.REWARDS}/${ROUTES.PROFILE}`),
        useMatch(`/${ROUTES.REWARDS}/${ROUTES.TRANSACTION}`),
        useMatch(`/${ROUTES.MEMBERSHIPS}`),
        useMatch(`/${ROUTES.MEMBERSHIP_STATE}`)
      ],
    },
    GIFTSHOP: {
      LABEL: "GIFT SHOP",
      TO: `/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`,
      PATHS: [
        useMatch(`/${ROUTES.GIFTSHOP}/${ROUTES.ECARDS}`),
        useMatch(`/${ROUTES.GIFTSHOP}/${ROUTES.PHYSICAL_CARDS}`),
        useMatch(`/${ROUTES.GIFTSHOP}/${ROUTES.EXPERIENCE}`),
      ],
    },
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const searchRef = useRef<HTMLInputElement | null>(null);

  const [menuState, setMenuState] = useState({
    movies: true,
    cinemas: true,
    account: true,
    giftshop: true,
    rewards: false
  });

  const [cinemaList, setCinemaList] = useState([]);
  const [showLocation, setShowLocation] = useState(false);
  const [showSearch, setShowSearch] = useState(false);

  useEffect(() => {
    if (countryId) {
      switch (countryId) {
        case COUNTRY.ANG:
          setMenuState({
            movies: true,
            cinemas: false,
            account: false,
            giftshop: true,
            rewards: true
          });
          break;
        case COUNTRY.STA:
          setMenuState({
            movies: true,
            cinemas: false,
            account: false,
            giftshop: true,
            rewards: true
          });
          break;
        default:
          setMenuState({
            movies: true,
            cinemas: false,
            account: false,
            giftshop: true,
            rewards: true
          });
          break;
      }
      dispatch(getCinemaWatcher({ countryId }));
    }
  }, [countryId]);

  useEffect(() => {
    if (cinemas && Object.keys(cinemas).length > 0) {
      const cine = JSON.parse(JSON.stringify(cinemas));
      if(cine['QLD'] && cine['QLD'].length > 0){
        cine['QLD'].push(LOCATION_ADD_ON.ANGELIKA)
      }
      if(cine['TAS'] && cine['TAS'].length > 0){
        cine['TAS'].push(LOCATION_ADD_ON.STATE)
      }
      setCinemaList(formatLocation(cine));
    }
  }, [cinemas]);

  useEffect(() => {
    if (pathname.includes(`${ROUTES.GIFTSHOP}/`) || [COUNTRY.ANG, COUNTRY.STA].includes(countryId)) {
      setShowLocation(false);
    } else {
      setShowLocation(true);
    }
  }, [pathname, countryId]);

  useEffect(() => {
    const sessionCinema = Storage.getItem("LOCAL", "currentCinema");
    if (cinemas && Object.keys(cinemas).length > 0) {
      const tempCinemas: any = Object.values(cinemas).flatMap((v) => v);

      if ([COUNTRY.STA, COUNTRY.ANG].includes(countryId)) {
        setLocation(tempCinemas[0].slug);
      } else {
        if (Object.keys(sessionCinema).length === 0) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              let location = formatCinemaNearby(position, cinemas);
              setLocation(location);
            },
            (error) => {},
            { enableHighAccuracy: true, timeout: 5000, maximumAge: 3000 }
          );
        } else {
          let currentCinema = findCinemaObject(cinemas, sessionCinema?.slug);
          dispatch(setCinemaAction(currentCinema?currentCinema:sessionCinema));
        }
      }
    }
  }, [cinemas]);

  const setLocation = (location: string) => {
    let currentCinema = findCinemaObject(cinemas, location);
    dispatch(setCinemaAction(currentCinema));
  };

  const onClickAccount = () => {
    if (isLoggedIn) {
      dispatch(showLogin(false));
      dispatch(openSliderAction({ open: true, type: "account" }));
    } else {
      dispatch(showLogin(true));
    }
  };

  const onSearch = (event: any) => {
    const {
      target: { value },
    } = event;

    if (event.key === "Enter") {
      if (value.trim()) {
        dispatch(searchMovie(value.trim()));
        navigate(`/${ROUTES.SEARCH_RESULT}`);
        if (searchRef.current && searchRef.current.value) {
          searchRef.current.value = "";
        }
        setShowSearch(countryId === COUNTRY.AFC?false:true);
      }
    }
  };

  const Menu = () => {
    return (
      <>
        {menuState.movies && (
          <li>
            <Link
              to={LINK.MOVIES.TO}
              className={LINK.MOVIES.PATHS.find((v) => v!!) ? "active" : ""}
            >
               <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+'tickets_white.svg'}/>
               <span>{LINK.MOVIES.LABEL}</span>
            </Link>
          </li>
        )}
        {menuState.cinemas && (
          <li>
            <Link
              to={LINK.CINEMAS.TO}
              className={LINK.CINEMAS.PATHS.find((v) => v!!) ? "active" : ""}
            >
               <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+'party_white.svg'}/>
               <span>{LINK.CINEMAS.LABEL}</span>
            </Link>
          </li>
        )}
        {menuState.rewards ? (
          <li>
            {
              <Link
                to={LINK.RWARDS.TO}
                className={LINK.RWARDS.PATHS.find((v) => v!!) ? "active" : ""}
              >
                <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+'party_white.svg'}/>
                <span>{LINK.RWARDS.LABEL}</span>
              </Link>
            }
          </li>
        ) : null}
        {/* {menuState.account && (
          <li className="d-none d-md-block">
            <a onClick={onClickAccount}>{LINK.ACCOUNTS.LABEL}</a>
          </li>
        )} */}
        {menuState.giftshop && (
          <li className={`${LINK.GIFTSHOP.PATHS.find((v) => v!!) ? "gift-shop-menu-hide" : ""}`}>
            <Link
              to={LINK.GIFTSHOP.TO}
              className={`gift-shop-menu ${
                LINK.GIFTSHOP.PATHS.find((v) => v!!) ? "active" : ""
              }`}
            >
               <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+'gift.svg'}/>
              <span>{LINK.GIFTSHOP.LABEL}</span>
            </Link>
          </li>
        )}
      </>
    );
  };

  const SearchBox = () => {
    return  <div className={`my-2 my-md-0 search-opt angelika`}>
      {!showSearch ?<img style={{width:20, marginLeft: 265}} onClick={()=>setShowSearch(true)} src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+"search_icon_white.svg"}/>:
        <div className="inner-addon right-addon">
          <input
            ref={searchRef}
            type="text"
            className="form-control"
            autoComplete="off"
            name="search"
            placeholder={
              countryId === COUNTRY.STA || countryId === COUNTRY.ANG
                ? "FILM SEARCH"
                : "Search"
            }
            onKeyUp={onSearch}
          />
        </div>}
      </div>
  };

  const openCart = () => {
    if (cart.length === 0) {
      toast.warning("No items found");
      return;
    } else {
      navigate(`/${ROUTES.GIFTSHOP}/${ROUTES.GIFT_CART}`);
    }
  };

  const showCart = useMemo(() => {
    return pathname.includes(`/${ROUTES.GIFTSHOP}/`);
  }, [pathname]);

  const Links = () => {
    switch (countryId) {
      case COUNTRY.STA:
        return (
          <>
            <Link
              to={`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`}
              className="state-logo"
            >
              <img
                src={URLS.STATE_LOGO}
                alt="State Cinema"
                title="State Cinema"
              />
            </Link>
            <Link
              to={URLS.ANGELIKA_URL}
              className="head-btn head-btn-ang"
              target="_blank"
            >
              <img src={URLS.ANGELIKA_LINK_LOGO} alt="Angelika Cinemas" />
              <span className="btn-text">Angelika Cinemas</span>
            </Link>
          </>
        );
      case COUNTRY.ANG:
        return (
          <>
            <Link
              to={`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`}
              className="ang-logo"
            >
              <img src={URLS.ANGELIKA_LOGO} alt="Angelika" title="Angelika" />
            </Link>
            <Link
              to={URLS.STATE_CINEMA_URL}
              className="head-btn head-btn-ang head-btn-stg"
              target="_blank"
            >
              <img src={URLS.STATE_LINK_LOGO} alt="State Cinema" />
            </Link>
          </>
        );
      default:
        return (
          <Link className="navbar-brand" to={`/${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`} >
            <img
              src={URLS.READING_LOGO}
              alt="Reading Cinemas"
              title="Reading Cinemas"
            />
          </Link>
        );
    }
  };

  const setSlider = () => {
    dispatch(openSliderAction({ open: true, type: "menu" }));
  };

  const onChangeCinema = (value: string) => {
    if(value === LOCATION_ADD_ON.ANGELIKA.slug|| value === LOCATION_ADD_ON.STATE.slug){
      window.open(value === LOCATION_ADD_ON.ANGELIKA.slug?URLS.ANGELIKA_URL:URLS.STATE_CINEMA_URL, '_blank')
      return;
    }
    if (cinemas) {
      dispatch(setCinemaAction(findCinemaObject(cinemas, value)));
    }
  };

  const GiftCart = () => {
    return (
      <div className="cust-cart-items top-menu-cust-cart-items">
        <a onClick={openCart}>
          {cart.length} <span>Items</span> <img src={URLS.CART_IMAGE} alt="" />
        </a>
      </div>
    );
  };

  return (
    <div>
      <header>
        <nav
          className={`navbar navbar-expand-md navbar-dark bg-dark ${
            countryId === COUNTRY.ANG ? "navbar_ang_cls" : ""
          } ${countryId === COUNTRY.STA ? "state-header" : ""} `}
        >
          <Links />
          {showLocation ? (
            <div className="navbar-nav mr-lg-auto user_menu nz_menu">
            <div className="location-wrapper">
              <LocationDropDown
                value={
                  currentCinema
                    ? { label: currentCinema.name, value: currentCinema.slug }
                    : ""
                }
                options={cinemaList}
                placeholder="Select Cinema"
                onChange={onChangeCinema}
              />
            </div>
            </div>
          ) : null}
          <div className="collapse navbar-collapse desktop_top_menu">
            <ul className={`gift-card-mobile-hide showHomeFootMenu`}>
              <Menu />
            </ul>
            {!showCart ? <SearchBox /> : null}
            {showCart ? <GiftCart /> : null}
      
              <div className="profile-wrapper" onClick={onClickAccount}>
                {isLoggedIn ? <><img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}user_icon.svg`} />
                  <div>
                    {userDetails?.firstName ? userDetails.firstName : ""}</div></>
                  : <>
                   <div className="ml-4"><span>LOGIN / SIGN UP</span></div>
                    <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}user_icon.svg`} />
                    </>}
              </div>
            <div className="form-inline my-2 my-md-0 hamburger_icon account-set">
              <a className="navbar-toggler-icon" onClick={setSlider}>
                Side Menu
              </a>
            </div>
          </div>
        </nav>
        <div className="mobile_bottom_menu col-md-12 w-100">
          <ul
            className={
              showCart
                ? `gift-card-mobile-show gift-card-mobile-bottom showHomeFootMenu"`
                : `gift-card-mobile-hide showHomeFootMenu`
            }
          >
            <Menu />
            {showCart ? (
              <>
                <li className="mobile-show cust-cart-items-main">
                  <GiftCart />
                </li>
              </>
            ) : null}
            {!showCart ?
              <div className="profile-wrapper" onClick={onClickAccount}>
                <img src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}user_icon.svg`} />
                {isLoggedIn ?<span>{userDetails?.firstName ? userDetails.firstName:""}</span> : <span>LOGIN /<br/> SIGN UP</span>}
              </div> : null}
            <li className="hamburger_icon account-set">
              <a className="navbar-toggler-icon" onClick={setSlider}>
                Side Menu
              </a>
            </li>
          </ul>
        </div>
      </header>
    </div>
  );
};

export default Header;
