import React, { FC, useMemo, useState } from "react";
import { RootState } from "../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_URL_CONFIG, URLS } from "../../../../constants/url";
import Barcode from "react-barcode";
import { IUpcomingTicket } from "../../../../models/auth";
import moment from "moment";
import QRCode from "react-qr-code";
import { encryptData } from "../../../../utils/helper";
import { WALLET_PASS_KEY } from "../../../../constants/app";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../../constants/path";
import { openSliderAction } from "../../../../store/application/action";
import Modal from "../../../../components/UI/Modal";
import './ticketDetails.scss';

interface ITicketDetailsProps {
    data: IUpcomingTicket,
    setShowTickets: React.Dispatch<React.SetStateAction<boolean>>;
    type: string;
}


const TicketDetails: FC<ITicketDetailsProps> = ({ data, setShowTickets, type }) => {
    const { countryId, userDetails, currentCinema } = useSelector(
        (state: RootState) => ({
            countryId: state.applicationReducer.countryId,
            userDetails: state.authReducer.userDetails,
            currentCinema: state.applicationReducer.currentCinema
        })
    );

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [modal, setModal] = useState(false);

    const googleWalletUrl = useMemo(() => {
        const payload = encryptData(
            JSON.stringify({
                id: data.ticketsaleId,
                emailId: userDetails?.email,
                type: "ticket",
                wallet: "android",
                countryId: countryId,
                device: 'web'
            }),
            WALLET_PASS_KEY!
        );
        return `${URLS.API_BASE_URL}/applewallet/getpass?id=${encodeURIComponent(payload)}`;
    }, [data, userDetails])

    const openFB = () => {
        navigate(`/${ROUTES.CINEMAS}/${currentCinema.slug}/foodandbevarages`);
        dispatch(openSliderAction({ open: false, type: "" }));
    }

    const iamHere = ()=>{
        navigate(`/am-here/${data.ticketsaleId}`);
        dispatch(openSliderAction({ open: false, type: "" }));
    }


    const appleWalletUrl = useMemo(() => {
        const payload = encryptData(
            JSON.stringify({
                id: data.ticketsaleId,
                emailId: userDetails?.email,
                type: "ticket",
                wallet: "apple",
                countryId: countryId,
                device: 'web'
            }),
            WALLET_PASS_KEY!
        );
        return `${URLS.API_BASE_URL}/applewallet/getpass?id=${encodeURIComponent(payload)}`;
    }, [data, userDetails])

    const showAppleWallet = useMemo(() => {
        if (
            /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        ) {
            return true;
        } else {
            return false;
        }
    }, [])

    const openModal = () => {
        setModal(true);
    }

    const onCloseModal = () => {
        setModal(false);
    }
    

    return (<>
        <div className="ticket-detail-wrapper">
            <div className="back-btn" onClick={() => setShowTickets(false)}>
                <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'arrow_left_dark_grey.svg'} />
                <span >BACK</span>
            </div>
            <div className="ticket-details">
                <div className="header">
                    <div className="movie-desc">
                        <img src={data.movieImage} />
                        <div className="content">
                            <div className="date">{moment(data.sesionDate).format('dddd DD MMMM')}</div>
                            <div className="name">{data.movieName}</div>
                            <div className="cinema">
                                <span className="time">{data.sesionTime}</span>
                                <span className="text">{data.cinemaName}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="body">
                    <div className="content">
                        <div className="label">Time</div>
                        <div className="value">{data.sesionTime}</div>
                    </div>
                    <div className="content">
                        <div className="label">Date</div>
                        <div className="value">{moment(data.sesionDate).format('dddd DD MMMM YYYY')}</div>
                    </div>
                    <div className="content">
                        <div className="label">Cinema</div>
                        <div className="value">{data.cinemaName}</div>
                    </div>
                    {/* <div className="content">
                        <div className="label">Cinema #</div>
                        <div className="value"></div>
                    </div> */}
                    <div className="content">
                        <div className="label">Seats</div>
                        <div className="value">{data.seats}</div>
                    </div>
                    <div className="divider"></div>
                    {data.BookingFee ? <div className="content-sm">
                        <div className="label">Booking Fee</div>
                        <div className="value">${data.BookingFee}</div>
                    </div> : null}
                    {data.ticketAmount ? <div className="content-sm">
                        <div className="label">Ticket Price Inclu. Booking Fee</div>
                        <div className="value">${(Number(data.ticketAmount) + Number(data.BookingFee)).toFixed(2)}</div>
                    </div> : null}
                    {data.concessionAmount ? <div className="content">
                        <div className="label">Total Price Incl. Food & Beverage</div>
                        <div className="value">${(Number(data.ticketAmount ? data.ticketAmount : 0) + Number(data.BookingFee ? data.BookingFee : 0) + Number(data.concessionAmount)).toFixed(2)}</div>
                    </div> : null}
                </div>
            </div>
            {data.concessionDetails && data.concessionDetails.length > 0 ? <div className="food-beverage-items">

                <div className="header">
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'food_dining.svg'} />
                    <h4>Food & Beverage</h4>
                </div>
                <div className="body">
                    <table>
                        <tbody>
                            {data.concessionDetails.map(v => <tr>
                                <td className="item">
                                    <div className="title">{v.Description}</div>
                                    <div className="items">
                                        {v.smartModifierGroups.map(v => v.selected).join(',')}
                                    </div>
                                    <div className="divider"></div>
                                    <div className="delivery">
                                        {v.deliveryInfo && v.deliveryInfo?.DeliveryOption? <span>
                                            <span>{v.Quantity} x (Seat Delivery)</span>
                                        </span> :
                                            <span>{v.Quantity} x (Self collect from counter)</span>
                                        }
                                    </div>
                                </td>
                                <td className="price">${v.Price}</td>
                            </tr>)}
                            <tr>
                                <td colSpan={2} className="total-price">
                                    <div className="label">Total Price</div>
                                    <div className="amount">${data.concessionAmount}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    {type === 'upcoming' ?<div className="content">
                        <p>Please select the “I’m At The Cinema” button below <span>when you have arrived at the cinema</span> so we can prepare your order.</p>
                        <p>If you have nominated ‘Seat Service’ for any food items you don’t need to do anything! We will deliver your meal to your seats at your preferred time as nominated at checkout.</p>
                        <div className="buttons">
                            <button className="btn-red" onClick={iamHere}>I’m at the cinema</button>
                            <button className="btn-black" onClick={openFB}>
                                <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH+'plus_16x16_white.svg'}/>Add Food & Beverage</button>
                            </div>
                    </div>:null}
                </div>

            </div> : type === 'upcoming' ? <div className="food-beverage">
                <div className="food-img">
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'tickets.svg'} />
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'drink.svg'} />
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'choctop.svg'} />
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'popcorn.svg'} />
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'tickets.svg'} />
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'drink.svg'} />
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'choctop.svg'} />
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'popcorn.svg'} />
                </div>
                <div className="content">
                    <h4>Enjoy food & beverage with your movie!</h4>
                    <p>We can deliver food and drink to your seat before or during the movie. Or you can self collect from the counter upon your arrival at the cinema.</p>
                    <button onClick={openFB}><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'plus_48x8_black.svg'} />Add Food & Beverage</button>
                </div>
            </div>: null}
            <div className="booking-code">
                <div className="header">BOOKING NUMBER</div>
                <div className="booking-number">{data.bookingId}</div>
                <div className="qr-code"><QRCode
                    size={100}
                    value={data.bookingId}
                />
                </div>
            </div>
            {type === 'upcoming' ? <div className="add-wallet">
                <div className="add-ticket">Add ticket(s) to:</div>
                <div className="wallet-button">
                    {showAppleWallet ? <div className="apple-wallet-btn"><a href={appleWalletUrl} target="_blank"><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'apple_wallet.svg'} /></a></div> :
                        <div className="google-wallet-btn"><a href={googleWalletUrl} target="_blank"><img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'google_wallet.svg'} /></a></div>
                    }
                </div>
            </div> : null}
            {type === 'upcoming' && currentCinema.barcodePrint ? <div className="parking-code">
                <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'car.svg'} />
                <h5>Parking Barcode</h5>
                <button onClick={openModal}>
                    <img src={IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH + 'scan_barcode.svg'} />
                    View/scan
                </button>
            </div> : null}
        </div>
        <Modal
            className={`barcode-viewer content-modal`}
            visible={modal}
            showCloseBtn={false}
        >
            <div className="modal fade show" style={{ display: "block" }}>
                <div
                    className={"modal-dialog modal-dialog-centered modal-md"}
                >
                    <div className={`modal-content`}>
                        <div className="modal-header-image">
                            <button
                                type="button"
                                className="close-modal"
                                onClick={onCloseModal}
                            >
                                <img
                                    className="close_icon"
                                    src={URLS.CLOSE_ICON}
                                    alt="close_icon"
                                    title="close_icon"
                                />
                            </button>
                        </div>
                        <div className="modal-header">
                            <h4>Scan Parking Barcode.</h4>
                        </div>
                        <div className="modal-body">
                            <div className="contact-us-form form_page">
                                <div className="row">
                                    <div className="col-12 text-center">

                                        <Barcode
                                            displayValue={false}
                                            value={moment.parseZone(data.sesionDate).format('YYYYMMDD') + data.bookingId}
                                        />
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="button_wrap text-center div">
                                            <button
                                                type="button"
                                                className="btn black_btn mx-1"
                                                onClick={() => onCloseModal()}
                                            >
                                                CLOSE
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>

    </>
    );
};

export default TicketDetails;
