import React, { FC } from "react";
import { Routes, Route } from "react-router";
import { Navigate } from "react-router-dom";
import PublicRoutes from "../../components/Routes/PublicRoutes";
import { ROUTES } from "../../constants/path";
import Cinemas from "../AU/Cinemas";
import GiftShop from "../Common/GiftShop";
import GiftCards from "../Common/GiftShop/Cards";
import Movies from "../AU/Movies";
import Activities from "../AU/Movies/Activities";
import ComingSoon from "../AU/Movies/ComingSoon";
import MovieDetails from "../AU/Movies/MovieDetails";
import NowShowing from "../AU/Movies/NowShowing";
import PrintSession from "../AU/Movies/PrintSession";
import SpecialEvents from "../AU/Movies/SpecialEvents";
import Deals from "../Common/ContentPage/Deals";
import Faq from "../Common/ContentPage//Faq";
import FoodAndBeverage from "../Common/FoodAndBeverage";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import AULayout from "../../layout/AU";
import { BRANDS } from "../../constants/app";
import Ticketing from "../Common/Ticketing";
import Membership from "../Common/Membership";
import Rewards from "../AU/Rewards";
import Summary from "../AU/Rewards/Summary";
import RewardItems from "../AU/Rewards/RewardItems";
import Benefits from "../AU/Rewards/MyBenefits";
import Profile from "../AU/Rewards/Profile";
import Transaction from "../AU/Rewards/Transaction";
import ContactUs from "../Common/ContentPage/ContactUs";
import PrivacyPolicy from "../Common/ContentPage/PrivacyPolicy";
import Terms from "../Common/ContentPage/Terms";
import RatingIndex from "../Common/ContentPage/RatingIndex";
import GroupSale from "../Common/ContentPage/GroupSale";
import CinemaHire from "../Common/ContentPage/CinemaHire";
import VipCinemas from "../Common/ContentPage/VipCinemas";
import GiftCart from "../Common/GiftShop/Cart";
import ResetPassword from "../Common/ContentPage/ResetPassword";
import ConfirmUser from "../Common/ContentPage/ConfirmUser";
import NewsLetter from "../Common/ContentPage/NewsLetter";
import GamingHires from "../Common/ContentPage/GamingHire";
import History from "../Common/ContentPage/History";
import Competitions from "../Common/ContentPage/Competitions";
import CafeAndBar from "../Common/ContentPage/CafeAndBar";
import Advertising from "../Common/ContentPage/Advertising";
import SohoLounge from "../Common/ContentPage/SohoLounge";
import PageNotFound from "../Common/PageNotFound";
import SearchAU from "../AU/Search";
import About from "../Common/ContentPage/About";
import HighlineTerrace from "../Common/ContentPage/HighlineTerrace";
import FoodCollect from "../Common/Modals/FoodCollect";
import LuxuryCinemas from "../Common/ContentPage/LuxuryCinemas";
import ScreenCategory from "../Common/ContentPage/ScreenCategory";
import LuxuryCinemasSession from "../AU/Movies/LuxuryCinemasSession";
import UpdateUser from "../Common/ContentPage/UpdateUser";

interface RoutesProps {}

const AppRoutes: FC<RoutesProps> = () => {
  const { brandId, currentCinema } = useSelector((state: RootState) => ({
    brandId: state.applicationReducer.brandId,
    currentCinema: state.applicationReducer.currentCinema,
  }));

  return <Routes>
      {/* Movie Pages */}
      <Route
        path={ROUTES.MOVIES}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={ROUTES.NOW_SHOWING} element={<NowShowing />} />
        <Route path={ROUTES.COMING_SOON} element={<ComingSoon />} />
        <Route path={ROUTES.SPECIAL_EVENTS} element={<SpecialEvents />} />
        <Route path={ROUTES.KIDS_NOW_SHOWING} element={<NowShowing />} />
        <Route path={ROUTES.KIDS_COMING_SOON} element={<ComingSoon />} />
        <Route path={ROUTES.KIDS_ACTIVITIES} element={<Activities />} />
      </Route>
    
      <Route
        path={ROUTES.MOVIE_DETAIL_SEARCH}
        element={<PublicRoutes layout={AULayout} component={MovieDetails} />}
      />
        <Route
        path={ROUTES.MOVIE_DETAILS_AU}
        element={<PublicRoutes layout={AULayout} component={MovieDetails} />}
      />
      <Route path={ROUTES.PRINT_SESSIONS} element={<PrintSession />}></Route>

      {/* Ticket Booking*/}
      <Route
        path={ROUTES.TICKET_BOOKING}
        element={<PublicRoutes layout={AULayout} component={Ticketing} />}
      ></Route>

      <Route
        path={`${ROUTES.PAGE_NOT_FOUND}`}
        element={<PublicRoutes layout={AULayout} component={PageNotFound} />}
      />
      {/* Food and beverage */}
      <Route
        path={ROUTES.FOOD_AND_BEVERAGE}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={""} element={<FoodAndBeverage />} />
      </Route>
       {/* Food and beverage I am here*/}
       <Route
        path={"am-here"}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={":code"} element={<FoodCollect/>} />
      </Route>
     

      {/* Cinemas */}
      <Route
        path={ROUTES.CINEMAS}
        element={<PublicRoutes layout={AULayout} component={Cinemas} />}
      ></Route>

      {/* vip experience - disabled temporarly based on the request*/}
      {/* <Route
        path={`${ROUTES.VIP_EXPERIENCE}`}
        element={<PublicRoutes layout={AULayout} component={VipCinemas} />}
      /> */}
      {/* Group booking link */}
      <Route 
        path={`${ROUTES.GROUPS}`}
        element={<PublicRoutes layout={AULayout} component={GroupSale} />}
      />
      {/* history */}
      <Route
        path={`${ROUTES.HISTORY}`}
        element={<PublicRoutes layout={AULayout} component={History} />}
      />
      <Route
        path={`${ROUTES.ABOUT}`}
        element={<PublicRoutes layout={AULayout} component={About} />}
      />
      {/* Membership State */}
      <Route
      path={`${ROUTES.MEMBERSHIP_STATE}`}
      element={<PublicRoutes layout={AULayout} component={Membership} />}
      />
      <Route
      path={`${ROUTES.MEMBERSHIPS}`}
      element={<PublicRoutes layout={AULayout} component={Membership} />}
      />
      <Route
        path={`${ROUTES.REWARDS}`}
        element={<PublicRoutes layout={AULayout} component={Rewards} />}
      >
        <Route path={ROUTES.SUMMARY} element={<Summary />} />
        <Route path={ROUTES.REWARDITEM} element={<RewardItems />} />
        <Route path={ROUTES.PROFILE} element={<Profile />} />
        <Route path={ROUTES.BENEFITS} element={<Benefits />} />
        <Route path={ROUTES.TRANSACTION} element={<Transaction />} />
        <Route path={ROUTES.RENEWAL} element={<Summary />} />
      </Route>

      {/* Cafe & Bar */}
      <Route
        path={`${ROUTES.CAFEBAR}`}
        element={<PublicRoutes layout={AULayout} component={CafeAndBar} />}
      />
      <Route
        path={`${ROUTES.HIGHLINE_TERRACE}`}
        element={<PublicRoutes layout={AULayout} component={HighlineTerrace} />}
      />

      {/* advertising */}
      <Route
        path={`${ROUTES.ADVERTISING}`}
        element={<PublicRoutes layout={AULayout} component={Advertising} />}
      />
      {/* reel club */}
      <Route
        path={`${ROUTES.REELCLUB}`}
        element={<Navigate replace to={`/${ROUTES.REWARDS}`}/>}
      />
      {/* Search */}
      <Route
        path={`${ROUTES.SEARCH_RESULT}`}
        element={<PublicRoutes layout={AULayout} component={SearchAU} />}
      />

      {/* Competitions */}
      <Route
        path={`${ROUTES.COMPETITIONS}`}
        element={<PublicRoutes layout={AULayout} component={Competitions} />}
      />
      {/* mailinglist */}
      <Route
        path={`${ROUTES.MAILINGLIST}`}
        element={<PublicRoutes layout={AULayout} component={Competitions} />}
      />

      {/* SohoLounge */}
      <Route
        path={`${ROUTES.SOHOLOUNGE}`}
        element={<PublicRoutes layout={AULayout} component={SohoLounge} />}
      />

      {/* gaming hire */}
      <Route
        path={`${ROUTES.GAMING_HIRE}`}
        element={<PublicRoutes layout={AULayout} component={GamingHires} />}
      />

      {/* Gift Shop */}
      <Route
        path={ROUTES.GIFTSHOP}
        element={<PublicRoutes layout={AULayout} component={GiftShop} />}
      >
        <Route path={ROUTES.ECARDS} element={<GiftCards />} />
        <Route path={ROUTES.PHYSICAL_CARDS} element={<GiftCards />} />
        <Route path={ROUTES.EXPERIENCE} element={<GiftCards />} />
      </Route>

      <Route
        path={`/${ROUTES.GIFTSHOP}/${ROUTES.GIFT_CART}`}
        element={<PublicRoutes layout={AULayout} component={GiftCart} />}
      />

      {/* Side Menus */}
      <Route
        path={ROUTES.OFFERS_EVENTS}
        element={<PublicRoutes layout={AULayout} component={Deals} />}
      />
      <Route
        path={ROUTES.FAQ}
        element={<PublicRoutes layout={AULayout} component={Faq} />}
      />
      <Route
        path={ROUTES.CINEMA_HIRE}
        element={<PublicRoutes layout={AULayout} component={CinemaHire} />}
      />
      <Route
        path={ROUTES.LUXURY_CINEMAS}
        element={<PublicRoutes layout={AULayout} component={LuxuryCinemas} />}
      />
      <Route
        path={ROUTES.LUXURY_CINEMAS_CATEGORY}
        element={<PublicRoutes layout={AULayout} component={ScreenCategory} />}
      />

      <Route path={ROUTES.LUXURY_CINEMAS_SESSION}
        element={<PublicRoutes layout={AULayout} component={LuxuryCinemasSession} />}
      />
      {/* account Verification */}
      <Route
        path={ROUTES.CONFIRM_USER}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={`:code`} element={<ConfirmUser />} />
      </Route>
      <Route
        path={ROUTES.UPDATE_USER}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={`:code`} element={<UpdateUser />} />
      </Route>

      <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPolicy />}>
        {/* <Route path={":type"} element={null} /> */}
      </Route>
      <Route path={ROUTES.CONTACT_US} element={<ContactUs />}>
        {/* <Route path={":type"} element={null} /> */}
      </Route>
      <Route
        path={`${ROUTES.RESET_PASSWORD}`}
        element={<PublicRoutes layout={AULayout} component={Movies} />}
      >
        <Route path={`:code`} element={<ResetPassword />} />
      </Route>
      {/* Wild card */}
      <Route
        path="*"
        element={<Navigate to={`${ROUTES.MOVIES}/${ROUTES.NOW_SHOWING}`} />}
      />
    </Routes>
};

export default AppRoutes;
