import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { IMAGE_URL_CONFIG } from '../../../constants/url';
import { getPromoApi } from '../../../services/application';
import HtmlRender from '../HtmlRender';
import "./pencilBanner.scss"

interface IPencilBannerProps { }

const PencilBanner: FC<IPencilBannerProps> = () => {

    const { currentCinema } = useSelector((state: RootState) => ({
        currentCinema: state.applicationReducer.currentCinema
    }))

    const [banner, setBanner] = useState([]);

    const getPencilBanner = async () => {
        const { status, response } = await getPromoApi({
            flag: "pencilBanner",
            countryId: currentCinema.slug,
        }) as any;

        if (status && response && response.data) {
            setBanner(response.data);
        }
    }

    useEffect(() => {
        if (currentCinema) {
            getPencilBanner()
        }
    }, [currentCinema])

    return banner.length > 0 ? <div className="pencilbanner">
        <div className="banner">
            <img
                className="slider-icon"
                src={`${IMAGE_URL_CONFIG.DEFAULT_IMAGE_PATH}warning-pencil-banner.svg`}
                alt="slider image"
            />

            <div className="slider-subheader">
                {banner &&
                    banner.map((data: any) => (
                        <HtmlRender {...{ className: "slider-text" }} content={data.description ? data.description : '<p></p>'} />
                    ))
                }
            </div>
        </div>
    </div> : null
}

export default PencilBanner;